import React from "react"
import PropTypes from "prop-types"
import ImmutablePropTypes from "react-immutable-proptypes"
import classNames from "classnames"
import { Map } from "immutable"
import { PaymentIcons } from "highline/components/icons"
import { adyenUrl } from "highline/utils/constants"
import styles from "highline/styles/components/paypal_button.module.css"

const PaypalButton = ({ adyenMerchantSignature, onPaypalButtonClick, paypalInputs }) => (
  <div className={classNames("paypal-button-component", styles.component)}>
    <form method="post" action={adyenUrl} id="adyenForm" name="adyenForm" target="_parent">
      <input type="hidden" name="merchantSig" value={adyenMerchantSignature} />
      {paypalInputs.entrySeq().map(([name, value]) => (
        <input type="hidden" name={name} value={value} key={name} />
      ))}
      <button
        className={styles.paypalButton}
        aria-label="Open Paypal payment page"
        onClick={onPaypalButtonClick}
        title="Paypal"
        type="submit"
      >
        <PaymentIcons.PaypalWhiteTextIcon className={styles.svg} />
      </button>
    </form>
  </div>
)

PaypalButton.propTypes = {
  adyenMerchantSignature: PropTypes.string,
  onPaypalButtonClick: PropTypes.func,
  paypalInputs: ImmutablePropTypes.map,
}

PaypalButton.defaultProps = {
  adyenMerchantSignature: "",
  onPaypalButtonClick: () => {},
  paypalInputs: Map(),
}

export default PaypalButton
