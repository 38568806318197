import getConfig from "highline/config/application"

export const shouldEnableApplePay = () => {
  const { enableApplePayExpressCheckout } = getConfig()

  if (enableApplePayExpressCheckout && typeof window !== "undefined") {
    return window?.ApplePaySession ? window.ApplePaySession.canMakePayments() : false
  }
  return false
}

export const shouldEnablePaypalExpress = (isLoggedIn) => {
  const { enablePaypalExpressCheckout } = getConfig()

  return !isLoggedIn && enablePaypalExpressCheckout
}
