import { List, Map } from "immutable"
import Rollbar, { formatHttpError } from "highline/utils/rollbar"
import { addLineItemsToCartAsync } from "highline/redux/actions/cart_actions"
import { getPromo } from "highline/redux/helpers/product_detail_helper"

import * as ProductDetailApi from "highline/api/product_detail_api"

import ActionTypes from "highline/redux/action_types"

export const productPreviewAddToCartClicked = () => ({
  type: ActionTypes.PRODUCT_PREVIEW_ADD_TO_CART_CLICKED,
})

export const productPreviewEducationCtaClicked = (optionTypeName) => ({
  type: ActionTypes.PRODUCT_PREVIEW_EDUCATION_CTA_CLICKED,
  optionTypeName,
})

export const productPreviewHelpLinkClicked = (helpLink) => ({
  type: ActionTypes.PRODUCT_PREVIEW_HELP_LINK_CLICKED,
  helpLink,
})

export const productPreviewZoomClicked = (direction) => ({
  type: ActionTypes.PRODUCT_PREVIEW_ZOOM_CLICKED,
  direction,
})

export const productPreviewOptionsChanged = (optionName, optionValue) => ({
  type: ActionTypes.PRODUCT_PREVIEW_OPTIONS_CHANGED,
  optionName,
  optionValue,
})

export const productPreviewFetchFailed = (error) => ({
  type: ActionTypes.PRODUCT_PREVIEW_FETCH_FAILED,
  error,
})

export const productPreviewFetchStarted = () => ({
  type: ActionTypes.PRODUCT_PREVIEW_FETCH_STARTED,
})

export const productPreviewFitEducationLoaded = (fitEducation) => ({
  type: ActionTypes.PRODUCT_PREVIEW_FIT_EDUCATION_LOADED,
  fitEducation,
})

export const productPreviewFetchSucceeded = (product, isFromOptionChange, promo, exclusions) => ({
  type: ActionTypes.PRODUCT_PREVIEW_FETCH_SUCCEEDED,
  product,
  isFromOptionChange,
  promo,
  exclusions,
})

export const productPreviewSoldOutClicked = () => ({
  type: ActionTypes.PRODUCT_PREVIEW_SOLD_OUT_CLICKED,
})

export const productPreviewThumbnailClicked = (url) => ({
  type: ActionTypes.PRODUCT_PREVIEW_THUMBNAIL_CLICKED,
  url,
})

export const productPreviewZoomOpenClicked = (url) => ({
  type: ActionTypes.PRODUCT_PREVIEW_ZOOM_OPEN_CLICKED,
  url,
})

export const productPreviewZoomCloseClicked = () => ({
  type: ActionTypes.PRODUCT_PREVIEW_ZOOM_CLOSE_CLICKED,
})

export const productPreviewLocationChanged = (res, redirectSlug) => ({
  type: ActionTypes.PRODUCT_PREVIEW_LOCATION_CHANGED,
  res,
  redirectSlug,
})

export const productPreviewRequestedWithOptions = (requestedOptions) => ({
  type: ActionTypes.PRODUCT_PREVIEW_REQUESTED_WITH_OPTIONS,
  requestedOptions,
})

export const productPreviewGiftCardInputChanged = (name, value) => ({
  type: ActionTypes.PRODUCT_PREVIEW_GIFT_CARD_INPUT_CHANGED,
  name,
  value,
})

export const productPreviewViewed = (product) => ({
  type: ActionTypes.PRODUCT_PREVIEW_VIEWED,
  product,
})

export const productPreviewModalViewed = (product) => ({
  type: ActionTypes.PRODUCT_PREVIEW_MODAL_VIEWED,
  product,
})

export const productPreviewOptionToggled = (optionName) => ({
  type: ActionTypes.PRODUCT_PREVIEW_OPTION_TOGGLED,
  optionName,
})

export const productPreviewSetNewProduct = (slug, selectedOptions) => ({
  type: ActionTypes.PRODUCT_PREVIEW_SET_NEW_PRODUCT,
  slug,
  selectedOptions,
})

export const productPreviewCleanAfterClickClose = () => ({
  type: ActionTypes.PRODUCT_PREVIEW_RESET_AFTER_CLICK_CLOSE,
})

export const productPreviewNotFoundError = (message) => ({
  type: ActionTypes.PRODUCT_PREVIEW_FETCH_NOT_FOUND,
  message,
})
export const productPreviewFetchAsync =
  (slug, selectedOptions, isFromOptionChange = false, res = null, location = null) =>
  async (dispatch, getState) => {
    dispatch(productPreviewFetchStarted())

    const options = selectedOptions.map((value) =>
      value !== "Semi Spread" ? value.toLowerCase() : "semi-spread"
    )

    try {
      const response = await ProductDetailApi.fetch(slug, options.toJS())
      const responseSlug = response.data.get("slug")

      const autoAppliedPromo = getState().getIn(["activePromotion", "promo"])
      const exclusions = getState().getIn(["promotion", "exclusions"])

      if (slug !== responseSlug) return dispatch(productPreviewLocationChanged(res, responseSlug))

      if (location === "quick shop") {
        dispatch(productPreviewViewed(response.data))
      } else if (location === "pdp modal") {
        dispatch(productPreviewModalViewed(response.data))
      }

      return dispatch(
        productPreviewFetchSucceeded(
          response.data,
          isFromOptionChange,
          autoAppliedPromo,
          exclusions
        )
      )
    } catch (error) {
      if (error?.status === 422 && options.get("color")) {
        try {
          // Fallback request to retrieve a product by color
          const innerResponse = await ProductDetailApi.fetch(slug, { color: options.get("color") })
          return dispatch(
            productPreviewFetchSucceeded(innerResponse.data, isFromOptionChange, {}, exclusions)
          )
        } catch (error) {
          logProductFetchFailed(error)
          return dispatch(productPreviewFetchFailed(error))
        }
      } else if (
        error?.status === 404 &&
        error?.data?.getIn(["errors", "product"])?.first()?.get("message") ===
          "Unable to find product"
      ) {
        logProductFetchFailed(error)
        return dispatch(
          productPreviewNotFoundError(
            error.data.getIn(["errors", "product"]).first().get("message")
          )
        )
      } else {
        logProductFetchFailed(error)
        return dispatch(productPreviewFetchFailed(error))
      }
    }
  }

export const productPreviewError = (message) => ({
  type: ActionTypes.PRODUCT_PREVIEW_FETCH_NOT_FOUND,
  message,
})

export const productPreviewUpdateOptionsAsync =
  (optionName, optionValue, updateQueryParams = true) =>
  (dispatch, getState) => {
    // don't allow deselect color
    if (optionName === "color" && !optionValue) return

    const selectedOptions = getState()
      .getIn(["productPreview", "selectedOptions"])
      .set(optionName, optionValue)

    const newSelectedOptions = optionValue
      ? selectedOptions.set(optionName, optionValue)
      : selectedOptions.delete(optionName)

    const slug = getState().getIn(["productPreview", "slug"])
    dispatch(productPreviewOptionsChanged(optionName, optionValue))
    dispatch(productPreviewFetchAsync(slug, newSelectedOptions, updateQueryParams))
  }

export const productPreviewAddToCartAsync = () => (dispatch, getState) => {
  if (getState().getIn(["productPreview", "isLoading"])) return // short circut request when loading

  const location =
    getState().getIn(["rightDrawer", "contents"]) === "quickShop" ? "quick shop" : null

  dispatch(productPreviewAddToCartClicked())
  const product = getState().get("productPreview")

  if (product.get("isPurchasable")) {
    const variant = product.get("variant")

    let lineItem = Map({
      id: variant.get("id"),
      isBundle: false,
      location,
      name: product.get("productName"),
      productSku: product.get("sku"),
      quantity: 1,
      sku: variant.get("sku"),
      slug: product.get("slug"),
    })

    if (product.get("isGiftCard") && product.get("isDigital")) {
      lineItem = lineItem.set("giftCardDetails", product.get("giftCardDetails"))
    }

    return dispatch(addLineItemsToCartAsync(List([lineItem])))
  }
}

export const productPreviewRequestedWithOptionsAsync = (requestedOptions) => async (dispatch) =>
  dispatch(productPreviewRequestedWithOptions(requestedOptions))

function logProductFetchFailed(error) {
  if (error.status === 422) Rollbar.error("422: Product Request Failed", formatHttpError(error))
}
