import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import PromoForm from "highline/components/cart/promo_form"
import { CirclePlusIcon, CircleMinusIcon } from "highline/components/icons"

import styles from "highline/styles/components/cart/promo_field.module.css"

const PromoField = ({
  error,
  onAddPromoSubmit,
  onRemovePromoSubmit,
  onTogglePromoField,
  onInputChange,
  code,
  isLoading,
  isPromoCodeApplied,
  isPromoFieldOpen,
  placeholder,
  className,
}) => (
  <div className={classNames("promo-form-component", styles.component)}>
    {!isPromoCodeApplied && (
      <div className={styles.togglePromoFieldContainer}>
        {isPromoFieldOpen && (
          <button onClick={onTogglePromoField} aria-label={"hide promo code field"}>
            <CircleMinusIcon />
          </button>
        )}
        {!isPromoFieldOpen && (
          <button onClick={onTogglePromoField} aria-label={"show promo code field"}>
            <CirclePlusIcon />
          </button>
        )}
        <button
          onClick={onTogglePromoField}
          aria-controls="promo-form-component"
          aria-expanded={isPromoFieldOpen}
          aria-label={`${isPromoFieldOpen ? "hide" : "show"} remove promo code field`}
        >
          Apply a promo code
        </button>
      </div>
    )}

    {!isPromoCodeApplied && isPromoFieldOpen && (
      <PromoForm
        className={className}
        error={error}
        isPromoCodeApplied={isPromoCodeApplied}
        isLoading={isLoading}
        code={code}
        onAddPromoSubmit={onAddPromoSubmit}
        onInputChange={onInputChange}
        placeholder={placeholder}
      />
    )}

    {isPromoCodeApplied && (
      <dl className={styles.promoCodeIsApplied}>
        Promo code applied: {code}
        <button onClick={onRemovePromoSubmit} aria-label={"remove promo code"}>
          Remove
        </button>
      </dl>
    )}
  </div>
)

PromoField.propTypes = {
  className: PropTypes.string,
  code: PropTypes.string,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  isPromoCodeApplied: PropTypes.bool,
  isPromoFieldOpen: PropTypes.bool,
  onAddPromoSubmit: PropTypes.func,
  onInputChange: PropTypes.func,
  onRemovePromoSubmit: PropTypes.func,
  onTogglePromoField: PropTypes.func,
  placeholder: PropTypes.string,
}

PromoField.defaultProps = {
  code: "",
  error: "",
  isLoading: false,
  isPromoCodeApplied: false,
  isPromoFieldOpen: false,
  onAddPromoSubmit: () => {},
  onInputChange: () => {},
  onRemovePromoSubmit: () => {},
  onTogglePromoField: () => {},
  placeholder: "Enter promo code",
}

export default PromoField
