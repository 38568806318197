import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { CheckedIcon, UncheckedIcon } from "highline/components/icons"

import styles from "highline/styles/components/checkbox.module.css"

const Checkbox = ({ checked, children, inputId, inputClassName, onChange, ...other }) => (
  <div className={classNames("checkbox-component", "component", styles.component)}>
    <label className={styles.label}>
      <input
        checked={checked}
        className={classNames(styles.input, inputClassName)}
        id={inputId}
        onChange={onChange}
        type="checkbox"
        {...other}
      />
      <div className={styles.checkbox}>{checked ? <CheckedIcon /> : <UncheckedIcon />}</div>
      <span className={styles.text}>{children}</span>
    </label>
  </div>
)

Checkbox.propTypes = {
  checked: PropTypes.bool,
  children: PropTypes.node,
  inputId: PropTypes.string,
  inputClassName: PropTypes.string,
  onChange: PropTypes.func,
}

Checkbox.defaultProps = {
  onChange: () => {},
}

export default Checkbox
